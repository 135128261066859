const moment = require("moment");

let type = 0;
let currentPrivateName = "";
$(document).ready(function () {
  if (window.location.href.indexOf("cameras") != -1) {
    var socket;
    const mainSocketMessageListener = (e) => {
      try {
        const message = JSON.parse(e.data);
        var page = document.getElementById("page").value;
        if (message.page === page) {
          if (message.from.length > 0 && message.to.length > 0) {
            managePrivateMessage(
              message.from,
              message.to,
              message.message,
              message.avatar
            );
          } else {
            addMessage(
              message.from,
              message.message,
              message.avatar,
              message.page
            );
          }
        }
      } catch (e) {
        // Catch any errors
      }
    };

    const mainSocketOpenListener = (event) => {
      console.log("Websocket opened");
      //Example of sending message to websocket here
      socket.send(
        JSON.stringify({
          event: "subscribe",
          data: ["all"],
        })
      );
    };

    const mainSocketCloseListener = (event) => {
      if (socket) {
        console.error("Websocket disconnected.");
      }
      socket = new WebSocket("wss://ws.imagodns.com:443");
      socket.addEventListener("open", mainSocketOpenListener);
      socket.addEventListener("message", mainSocketMessageListener);
      socket.addEventListener("close", mainSocketCloseListener);
    };

    //connect the first time
    mainSocketCloseListener();
    $(document).on("keypress", "input", function (e) {
      /* ENTER PRESSED*/
      if (e.keyCode == 13) {
        $("#sendBtn").click();
        return false;
      }
    });
    $("#sendBtn").click(function () {
      let avatar = $("#name").data("avatar");
      let name = document.getElementById("name").value;
      let date = Date.now();
      if (name.length > 3) {
        if (type == 1) {
          const message = {
            from: name,
            to: currentPrivateName,
            message: document.getElementById("message").value,
            avatar: avatar,
            page: document.getElementById("page").value,
            date: date,
          };
          $.ajax({
            method: "POST",
            url: "/newchatmessage",
            data: message,
          }).done(function (msg) {
            console.log(msg);
          });
          sendMessage(1, socket, message);
        } else {
          let message = {
            from: name,
            to: "",
            message: document.getElementById("message").value,
            avatar: avatar,
            page: document.getElementById("page").value,
            date: date,
          };
          $.ajax({
            method: "POST",
            url: "/newchatmessage",
            data: message,
          }).done(function (msg) {
            console.log(msg);
          });
          sendMessage(0, socket, message);
        }
      }
    });
    $(".chatbox").click(function () {
      var user = $(this).data("user");
      $(".chatbox").each(function () {
        $(this).removeClass("activeBox");
      });
      $(this).addClass("activeBox");
      if (user != false) {
        if (user != "broadcaster") {
          $("#chatControls").show();
          preparePrivateMessage(user);
          filterPrivateMessage(user);
        } else {
          filterBroadcasterMessage();
          $("#chatControls").hide();
        }
      } else {
        $("#chatControls").show();
        $(".message").each(function () {
          $(this).show();
        });
      }
    });
  }
  bindBroadcasterClick();
  bindChatBoxClick();
});
function addMessage(name, message, avatar, type) {
  var date = moment().format('m/d/Y h:m');
  const messageHTML =
    "<div class='message' title='"+date+"'>" +
    "<span><img src=" +
    avatar +
    "></span>" +
    "<span class='broadCasterName' data-name='" +
    name +
    "'>" +
    name +
    "</span> " +
    message +
    "</div>";
  var newContent = messageHTML + "" + document.getElementById("chat").innerHTML;
  $("#chat").html(newContent);
  bindBroadcasterClick();
  bindChatBoxClick();
}
function addPrivateMessage(from, to, message, avatar) {
  const messageHTML =
    "<div class='message private_message' data-user='" +
    from +
    "_" +
    to +
    "'>" +
    "<span><img src=" +
    avatar +
    "></span>" +
    "<span class='broadCasterName' data-name='" +
    from +
    "'>" +
    from +
    "</span> " +
    message +
    "</div>";
  var newContent = messageHTML + "" + document.getElementById("chat").innerHTML;
  $("#chat").html(newContent);
  bindBroadcasterClick();
  bindChatBoxClick();
}
function managePrivateMessage(from, to, message, avatar) {
  var name = document.getElementById("name").value;
  if (name.length > 0) {
    if (to == name || from == name) {
      var to_escaped = to.replace(/\s/g, "");
      var from_escaped = from.replace(/\s/g, "");
      if (
        $(".privateChat_" + to_escaped + "").length > 0 ||
        $(".privateChat_" + from_escaped + "").length > 0
      ) {
        addPrivateMessage(from, to, message, avatar);
      } else {
        if (to == name) {
          createNewChatbox(from);
        } else if (from == name) {
          createNewChatbox(to);
        }
        addPrivateMessage(from, to, message, avatar);
      }
    }
  }
}
function pingUser(name) {
  var val = $("#message").val();
  var $messageBox = $("#message");
  var caretPos = $messageBox[0].selectionStart;
  var messageBoxTxt = $messageBox.val();
  var toAdd = "@" + name + "";
  $messageBox.val(
    messageBoxTxt.substring(0, caretPos) +
      toAdd +
      messageBoxTxt.substring(caretPos)
  );
}
function filterPrivateMessage(name) {
  if (name == false) {
    $(".message").each(function () {
      if ($(this).hasClass("private_message")) {
        $(this).hide();
      } else {
        $(this).show();
      }
    });
  } else {
    let users;
    $(".message").each(function () {
      if ($(this).hasClass("private_message")) {
        users = $(this).data("user").toString().split("_");
        if (users.indexOf(name) > -1) {
          $(this).show();
        } else {
          $(this).hide();
        }
      } else {
        $(this).hide();
      }
    });
  }
}
function filterBroadcasterMessage() {
  $(".message").each(function () {
    if ($(this).hasClass("broadcaster_message")) {
      $(this).show();
    } else {
      $(this).hide();
    }
  });
}
function preparePrivateMessage(name) {
  type = name == false ? 0 : 1;
  currentPrivateName = name;
  name = name == false ? $("#messageType").data("default") : name;
  $("#messageType").text(name);
}
function sendMessage(private, socket, message) {
  socket.send(JSON.stringify(message));
  if (private == 0) {
    addMessage(message.from, message.message, message.avatar, message.page);
  } else {
    managePrivateMessage(
      message.from,
      message.to,
      message.message,
      message.avatar,
      message.page
    );
  }
  $("#message").val("");
}
function bindBroadcasterClick() {
  var timer = 0;
  var delay = 200;
  var prevent = false;
  $(".broadCasterName").dblclick(function (e) {
    if ($(this).data("name") != document.getElementById("name").value) {
      clearTimeout(timer);
      prevent = true;
      preparePrivateMessage($(this).data("name"));
    }
  });
  $(".broadCasterName").click(function (e) {
    if ($(this).data("name") != document.getElementById("name").value) {
      var name = $(this).data("name");
      if (name.length > 0) {
        timer = setTimeout(function () {
          if (!prevent) {
            pingUser(name);
          }
          prevent = false;
        }, delay);
      }
    }
  });
}
function bindChatBoxClick() {
  $(".chatbox").click(function () {
    var user = $(this).data("user");
    $(".chatbox").each(function () {
      $(this).removeClass("activeBox");
    });
    $(this).addClass("activeBox");
    if (user != false) {
      if (user != "broadcaster") {
        $("#chatControls").show();
        preparePrivateMessage(user);
        filterPrivateMessage(user);
      } else {
        filterBroadcasterMessage();
        $("#chatControls").hide();
      }
    } else {
      $("#chatControls").show();
      $(".message").each(function () {
        $(this).show();
      });
      preparePrivateMessage(false);
    }
  });
}
function createNewChatbox(name) {
  var name_escaped = name.replace(/\s/g, "");
  $("#messageBox").append(
    "<div class='chatbox privateChat_" +
      name_escaped +
      "' data-user='" +
      name +
      "' >" +
      name +
      "</div>"
  );
}
